import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './VideoCarousel.css';

const VideoCarousel = ({ videos }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="video-carousel">
      <h2 className="carousel-header">The Smol Files</h2>
      <Slider {...settings}>
        {videos.map((video, index) => (
          <div key={index} className="video-wrapper">
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${video}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={`video-${index}`}
            ></iframe>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default VideoCarousel;
