// src/components/TelegramIcon.js
import React from 'react';

const TelegramIcon = () => (
  <svg width="44" height="44" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.004 0C5.372 0 0 5.372 0 12.004 0 18.627 5.372 24 12.004 24 18.627 24 24 18.627 24 12.004 24 5.372 18.627 0 12.004 0zm6.02 8.354l-1.52 7.2c-.114.514-.4.63-.81.394l-2.228-1.645-1.075 1.038c-.15.148-.274.274-.562.274l.202-2.853 5.183-4.695c.227-.2-.05-.311-.35-.112L8.856 11.97l-2.83-.882c-.514-.16-.53-.515.107-.76l11.04-4.22c.45-.164.845.112.682.756z"/>
  </svg>
);

export default TelegramIcon;
