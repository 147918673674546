import './App.css';
import About from './components/About';
import ChoosePfp from './components/ChoosePfp';
import Footer from './components/Footer';
import Header from './components/Header';
import Hero from './components/Hero';
import VideoCarousel from './components/VideoCarousel';
const videoIds = [
  'bt83d_gU3eM',
  '5nhPfNSkdPc',
  'BmVrywCOL_0',
  'B_UXM0Fb800',
];
function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <VideoCarousel videos={videoIds}/>
      <About />
      <ChoosePfp />
      <Footer />
     </div>
  )
}

export default App;
