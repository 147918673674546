// src/components/TwitterIcon.js
import React from 'react';

const TwitterIcon = () => (
  <svg width="44" height="44" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.953 4.569a10 10 0 01-2.825.775 4.932 4.932 0 002.163-2.723 9.865 9.865 0 01-3.127 1.184 4.92 4.92 0 00-8.384 4.482A13.978 13.978 0 011.671 3.149a4.822 4.822 0 001.524 6.574 4.902 4.902 0 01-2.229-.616c-.053 2.28 1.581 4.415 3.949 4.89a4.936 4.936 0 01-2.224.084 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.068a13.94 13.94 0 007.548 2.213c9.142 0 14.307-7.721 14.307-14.417 0-.22-.006-.437-.016-.653A10.18 10.18 0 0024 4.59a9.948 9.948 0 01-2.047.559z"/>
  </svg>
);

export default TwitterIcon;
