import React from 'react';
import TwitterIcon from './TwitterIcon';
import TelegramIcon from './TelegramIcon';
import MediumIcon from './MediumIcon';

import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="logo">SMOL</div>
      <div className="tagline">SMOL CAT<br />BIG DREAMS</div>
      <div className="social-icons">
        <a href="https://x.com/smol_solana" target="_blank" rel="noopener noreferrer">
          <TwitterIcon />
        </a>
        <a href="https://t.me/smolcatportal" target="_blank" rel="noopener noreferrer">
          <TelegramIcon />
        </a>
        <a href="https://medium.com/@web3jedi/riding-the-waves-the-resilient-journey-of-smol-cat-coin-from-pump-fun-to-raydium-21f8ebe1242f" target="_blank" rel="noopener noreferrer">
          <MediumIcon />
        </a>
      </div>
      <a href="https://jup.ag/swap/SOL-SMOL" target="_blank" rel="noopener noreferrer" className="buy-now-link">
        <button className="buy-now">BUY NOW</button>
      </a>
    </header>
  );
};

export default Header;
