import React from 'react';
import './About.css';
import magnifyingSmol from '../Images/maginifyingsmol.png'; // Update the path as needed

const About = () => {
  return (
    <div className="about">
      <img src={magnifyingSmol} alt="Magnifying Smol Cat" />
      <div>
        <p className="headingA">ABOUT SMOL</p>
        <p><span className="smollcat">SMOLCAT</span> IS A PROJECT ORIGINALLY LAUNCHED ON PUMP.FUN. THE DEV SOLD HIS TOKENS BUT THE COMMUNITY MADE HIM FOMO BACK IN AFTER THEY TOOK OVER.</p>
        <p><span className="smol">Smol</span> AIMS TO BE THE <span className="smol">SMOLEST</span> CAT ON <span className="solana">SOLANA</span> WITH THE BIGGEST MARKETCAP. HELP <span className="smol">SMOL</span> ACHIEVE THIS GOAL BY BUYING <span className="smol">SMOL</span> AND DREAMING BIG.</p>
      </div>
    </div>
  );
};

export default About;
