import React from 'react';
import './ChoosePfp.css';
import pfp1 from '../Images/smol/smol1.png'; // Update the path as needed
import pfp2 from '../Images/smol/smol2.png'; // Update the path as needed
import pfp3 from '../Images/smol/smol3.webp'; // Update the path as needed
import pfp4 from '../Images/smol/smol4.webp'; // Update the path as needed
import pfp5 from '../Images/smol/smol5.webp'; // Update the path as needed
import pfp6 from '../Images/smol/smol6.webp'; // Update the path as needed
import pfp7 from '../Images/smol/smol7.webp'; // Update the path as needed
import pfp8 from '../Images/smol/smol8.webp'; // Update the path as needed
import pfp9 from '../Images/smol/smol9.webp'; // Update the path as needed
import pfp10 from '../Images/smol/smol10.webp'; // Update the path as needed
import pfp11 from '../Images/smol/smol11.png'; // Update the path as needed
import pfp12 from '../Images/smol/smol12.webp'; // Update the path as needed

const ChoosePfp = () => {
  return (
    <div className="choose-pfp">
      <div className="header1">
        CHOOSE YOUR PFP
      </div>
      <div className="grid">
        <img src={pfp1} alt="PFP 1" />
        <img src={pfp2} alt="PFP 2" />
        <img src={pfp3} alt="PFP 3" />
        <img src={pfp4} alt="PFP 4" />
        <img src={pfp5} alt="PFP 5" />
        <img src={pfp6} alt="PFP 6" />
        <img src={pfp7} alt="PFP 7" />
        <img src={pfp8} alt="PFP 8" />
        <img src={pfp9} alt="PFP 9" />
        <img src={pfp10} alt="PFP 10" />
        <img src={pfp11} alt="PFP 11" />
        <img src={pfp12} alt="PFP 12" />
      </div>
      <button className="more-memes">More Memes</button>
    </div>
  );
};

export default ChoosePfp;
