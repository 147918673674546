import React from 'react';
import './Hero.css';
import speechBubble from '../Images/bubble.svg';

const Hero = () => {

  const copyToClipboard = () => {
    const address = "A9jLULrDSYZkBtKfvG44w8NWzvCN6rGLpoA3hY7TQ85k";
    navigator.clipboard.writeText(address).then(() => {
      alert("Contract address copied to clipboard!");
    }, (err) => {
      console.error("Failed to copy: ", err);
    });
  };

  return (
    <div className="hero">
      <div className="speech-bubble">
        <img src={speechBubble} alt="Speech Bubble" className="bubble-img" />
        <div className="bubble-text">PET ME FOR A SMOL SURPRISE</div>
      </div>
      <a href="https://smol-sooty.vercel.app/" target="_blank" rel="noopener noreferrer" className="cat-link">
        <img src='./smol.webp' alt="Smol Cat" className="cat-image" />
      </a>
      <div className="buttons">
        <a href="https://jup.ag/swap/SOL-SMOL" target="_blank" rel="noopener noreferrer" className="buy-nows-link">
          <button className="buy-nows">BUY NOW</button>
        </a>
        <a href="https://dexscreener.com/solana/fqw2crac6hntamr3mwhhpa8ncb9hy6xct47lmfcq9zng" target="_blank" rel="noopener noreferrer" className="view-chart-link">
          <button className="view-chart">VIEW CHART</button>
        </a>
      </div>
      <div className="contract-address" onClick={copyToClipboard}>
        CA: A9jlOLrDSYzkBtKfvG44w8NWzvCN6rGLpoA3hY7Q85k
      </div>
      <div className="token-info">
        <div>
          <span className="label">SUPPLY</span>
          <span className="amount">1,000,000,000</span>
        </div>
        <div>
          <span className="label">LP BURNED</span>
          <span className="amount">100%</span>
        </div>
        <div>
          <span className="label">TAX</span>
          <span className="amount">0/0</span>
        </div>
      </div>
    </div>
  );
};

export default Hero;
