import React from 'react';
import './Footer.css';
import coingecko from '../Images/coingecko.webp'; // Update the path as needed
import pumpfun from '../Images/pumpfun.webp'; // Update the path as needed
import dexscreener from '../Images/dexscreener.webp'; // Update the path as needed
import dextools from '../Images/dextools.webp'; // Update the path as needed
import memecoinseason from '../Images/Memecoinseason.png'; // Update the path as needed

const Footer = () => {
  return (
    <div className="footer">
      <div className="partner-text">PARTNERS:</div>
      <div className="partners">
        <a href="https://www.coingecko.com/en/coins/smol-cat" target="_blank" rel="noopener noreferrer">
          <img src={coingecko} alt="CoinGecko" className="coingecko" />
        </a>
        <a href="https://www.pump.fun/A9jLULrDSYZkBtKfvG44w8NWzvCN6rGLpoA3hY7TQ85k" target="_blank" rel="noopener noreferrer">
          <img src={pumpfun} alt="Pump.Fun" className="pumpfun" />
        </a>
        <a href="https://dexscreener.com/solana/fqw2crac6hntamr3mwhhpa8ncb9hy6xct47lmfcq9zng" target="_blank" rel="noopener noreferrer">
          <img src={dexscreener} alt="Dexscreener" className="dexscreener" />
        </a>
        <a href="https://www.dextools.io/app/en/solana/pair-explorer/FQW2crAc6hnTAMR3mwhhPA8nCB9hy6xcT47LmFcq9zNG?t=1719288145480" target="_blank" rel="noopener noreferrer">
          <img src={dextools} alt="Dextools" className="dextools" />
        </a>
        <a href="https://Memecoinseason.io" target="_blank" rel="noopener noreferrer">
          <img src={memecoinseason} alt="Memecoin Season" className="dextools" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
